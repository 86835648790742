/* @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* scrollbar styles */
::-webkit-scrollbar {
  @apply w-1 h-3 rounded;
}
::-webkit-scrollbar-thumb {
  @apply bg-accent/50 rounded;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-accent;
}

@layer base {
  html,
  body {
    font-family: "Lato", sans-serif;
  }

  img {
    @apply max-w-full max-h-full object-cover;
  }

  p {
    @apply text-body !text-sm;
  }

  .primary-text {
    @apply text-primary-np;
  }
}

@layer components {
  .status {
    @apply px-2 py-0.5 select-none text-xs font-medium leading-tight rounded-md;
  }

  .status-danger {
    @apply text-status-danger-text bg-status-danger-bg text-sm font-medium;
  }
  .status-done {
    @apply text-status-complete-text bg-status-complete-bg text-sm font-medium;
  }
  .status-waiting {
    @apply text-status-waiting-text bg-status-waiting-bg text-sm font-medium;
  }
  .status-info {
    @apply text-status-info-text bg-status-info-bg text-sm font-medium;
  }
  .status-pending {
    @apply text-status-pending-text bg-status-pending-bg text-sm font-medium;
  }

  .btn-status {
    @apply !text-white !rounded-lg !border-none !text-sm !font-medium;
  }
  .btn-status-danger {
    @apply !bg-status-danger-btn disabled:!bg-status-danger-btn/50 hover:!bg-status-danger-btn/90;
  }
  .btn-status-done {
    @apply !bg-status-complete-btn disabled:!bg-status-complete-btn/50 hover:!bg-status-complete-btn/90;
  }
  .btn-status-waiting {
    @apply !bg-status-waiting-btn disabled:!bg-status-waiting-btn/50 hover:!bg-status-waiting-btn/90;
  }
  .btn-status-info {
    @apply !bg-status-info-btn disabled:!bg-status-info-btn/50 hover:!bg-status-info-btn/90;
  }
  .btn-status-pending {
    @apply !bg-status-pending-btn disabled:!bg-status-pending-btn/50 hover:!bg-status-pending-btn/90;
  }

  .disable-btn,
  .paid_btn .ant-btn-default {
    @apply !bg-others-gray hover:!bg-others-gray/90 disabled:!bg-others-gray !rounded-lg !text-white !text-base !border-others-gray !font-medium leading-7;
  }
  /* .btn-status {
    @apply text-xs select-none rounded-lg py-3 px-4 inline-block text-primary-np font-semibold;
  }
   */

  .secondary-btn {
    @apply !bg-secondary-np hover:!bg-secondary-np/90 disabled:!shadow-none hover:disabled:!shadow-none !text-white !shadow-md hover:!shadow disabled:!bg-primary-np/70;
  }
  .red-btn {
    @apply !bg-status-danger-text hover:!bg-status-danger-text/90 disabled:!bg-status-danger-text/90 disabled:!shadow-none hover:disabled:!shadow-none !text-white !shadow-md hover:!shadow !border-none;
  }
  .recharge-btn {
    @apply !bg-others-secondary hover:!bg-others-secondary/90 !text-white !shadow-md hover:!shadow;
  }
  .upload-btn {
    @apply !bg-white hover:!bg-white/90 !text-status-pending-text border-status-pending-text !shadow-sm hover:!border-status-pending-text/90 hover:!shadow;
  }
}

/* ====== buoy icon animation ======> */
.buoyIcon {
  -webkit-animation: buoyIcon 2s linear infinite;
  animation: buoyIcon 2s linear infinite;
}

@keyframes buoyIcon {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* <====== buoy icon animation ====== */

/* change scrollbar color in sidebar */
.custom-sidebar {
  scrollbar-color: #fff #fff;
  scrollbar-width: none;
}

.custom-sidebar:hover {
  scrollbar-color: #ddd #fff;
}

/* mapbox */
.mapboxgl-control-container {
  @apply !hidden;
}

.dashboard_map .mapboxgl-canvas {
  @apply !rounded-xl !w-full object-cover;
}

.mapboxgl-canvas {
  @apply !w-full;
}

.roboto_font label,
.roboto_font input,
.roboto_font h3,
.roboto_font h6,
.roboto_font div,
.roboto_font span {
  @apply !font-roboto;
}

.apexcharts-canvas,
.apexcharts-svg,
.apexcharts-svg foreignObject,
.apexcharts-graphical {
  @apply !w-full !max-w-full;
}

.import_excel .ant-upload {
  @apply !border-none !p-0;
}

#form_item_path.custom-form .ant-checkbox-inner {
  @apply !rounded-sm;
}

.custom_edotco_table .ant-checkbox-inner,
.custom_edotco_table .ant-checkbox-input {
  transform: scale(1.2);
  @apply !rounded-none;
}

.hover-scrollbar {
  scrollbar-color: #fff #fff;
  scrollbar-width: thin;
}

.hover-scrollbar:hover {
  scrollbar-color: #ddd #fff;
}
